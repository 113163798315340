import { useNavigation } from "@remix-run/react";
import { ChevronsRight, CircleSlash, CornerLeftUp, EllipsisVertical, LoaderCircle } from "lucide-react";
import { parseCookies } from "oslo/cookie";
import { FC, useEffect, useState } from "react";

import { Button, ButtonProps } from "~/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { cn } from "~/lib/utils";

const actions = ["nothing", "list", "next"] as const;
type Action = (typeof actions)[number];

const content = {
  nothing: { label: "Semmi", icon: <CircleSlash className="ml-2 size-4" /> },
  list: { label: "Vissza a listához", icon: <CornerLeftUp className="ml-2 size-4" /> },
  next: { label: "Következő elemre", icon: <ChevronsRight className="ml-2 size-4" /> },
};

type SubmitButtonProps = {
  storageKey?: string;
} & ButtonProps;

const SubmitButton: FC<SubmitButtonProps> = ({
  children,
  className,
  formMethod = "POST",
  formAction,
  storageKey,
  ...props
}) => {
  const [action, setAction] = useState<Action | undefined>();

  const navigation = useNavigation();
  const pending =
    navigation.state === "submitting" &&
    (formMethod ? formMethod === navigation.formMethod : true) &&
    (formAction ? formAction === navigation.formAction : true);

  useEffect(() => {
    if (!storageKey) {
      return;
    }
    const map = parseCookies(document.cookie);
    const storedAction = map.get(storageKey) as Action;
    setAction(storedAction ?? "nothing");
  }, [storageKey]);

  useEffect(() => {
    if (!action) {
      return;
    }
    document.cookie = `${storageKey}=${action}; path=/; max-age=${60 * 60 * 24 * 7}`;
  }, [action, storageKey]);

  return (
    <div className={cn("self-start flex items-center gap-1", className)}>
      <Button
        type="submit"
        disabled={pending}
        className={cn("relative w-full", { "text-transparent": pending })}
        {...props}
      >
        {children}
        {pending && (
          <LoaderCircle className="text-primary-foreground animate-spin absolute w-6 h-6 -mt-3 -ml-3 top-[50%] left-[50%]" />
        )}
        {action && content[action].icon}
      </Button>
      {action && (
        <DropdownMenu>
          <DropdownMenuTrigger className="p-2">
            <EllipsisVertical className="size-5" />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="m-2 p-2">
            <p>Mi történjen mentés után?</p>
            <div className="-ml-4 mt-1 space-y-1">
              {actions.map((action) => {
                return (
                  <DropdownMenuItem key={action}>
                    <button className="w-full flex items-center gap-1.5 px-2.5 py-2" onClick={() => setAction(action)}>
                      {content[action].icon}
                      <span className="leading-none ml-1">{content[action].label}</span>
                    </button>
                  </DropdownMenuItem>
                );
              })}
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};

export default SubmitButton;
